
  
.progress {
  height: 5px;
  flex-grow: 1;
  display: flex;
//   background-image: -webkit-linear-gradient(left, 
//     rgba(255,255,255,.5) 0%,
//     rgba(255,255,255,.5) 50%,
//     rgba(88, 89, 104,.5) 50.001%,
//     rgba(88, 89, 104,.5) 100%
//   );
  background-repeat: no-repeat;
  background-size: 200%;
//   background-color: #666;
  background-position: 100% 50%;
  animation-timing-function: linear;
  animation-delay: .2s;

  transform-origin: left center;
  // transform: scaleX(0);
  transition: transform 2s;
 }
  
.progress.active {
  // animation-name: Loader;
  transform: scaleX(1);
  height: 5px;
 }
  
.progress.passed {
  // background-position: 0 0; 
  transform: scaleX(0);
 }
  
@keyframes Loader {
  0%   { background-position: 100% 0; }
  100% { background-position: 0 0; }
}
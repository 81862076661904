@import '../../styles/variables';

.globalModalWrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.9);
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
    overflow: hidden !important;
  
    &.open {
      opacity: 1;
      visibility: visible;
    }
  
    .globalModal {
      // width: 45%;
      //   height: 400px;
    //   padding: 24px 0;
    //   padding-bottom: 24px;
      background: $white;
      border-radius: 10px;
      opacity: 1;
      box-shadow: 5px 5px 5px 5px $neutral;
      transform: translateY(100%); /* Start from the bottom */
      transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  
      .head {
        width: 100%;
        height: 88px;
        padding: 24px 40px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $primary;
        // background: #01bce5;
      }

      .titleContainer {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
      }

      .step {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        padding: 0.125rem;
        background-color: $primary;
        border-radius: 1000px;
        color: white;
        font-size: 1.125rem;
        line-height: 1.75rem;
        text-align: center;
      }

      .title {
        font-size: 1.25rem;
        line-height: 1.75rem;
      }

      .time {
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-family: 'Aeonik Bold', sans-serif;
        text-transform: uppercase;
      }
  
      .btn-close {
        width: 32px;
        height: 32px;
        display: block;
        float: right;
        position: relative;
        cursor: pointer;
      }
  
      .btn-close::before,
      .btn-close::after {
        content: '';
        width: 32px;
        height: 6px;
        display: block;
        background: $white;
      }
  
      .btn-close::before {
        margin-top: 12px;
        transform: rotate(45deg);
      }
  
      .btn-close::after {
        margin-top: -6px;
        transform: rotate(-45deg);
      }
  
      .content {
        padding: 24px 40px;
        // border-bottom: 1px solid $primary;
        border-top: 1px solid $primary;
        color: $primary;
      }

      .footer {
        display: flex;
        justify-content: flex-end;
        padding-top: 16px;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 24px;
        background-color: $white;
        border-radius: 10px;
      }
    }
  
    &.open .globalModal {
      transform: translateY(0); /* Move to the top */
    }
  }

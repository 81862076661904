$bg:rgba(255, 255, 255, 1);
$fill:#15049E;
$width: 4em;
$height:8em;

.hourglass{
    display:block;
    background:$bg;
    margin:3em auto;
    width:$width;
    height:$height;
    animation:hourglass 1s linear infinite;
}

.outer{
    fill:$fill;
}

.middle{
    fill:$bg;
}

@keyframes hourglass{
    0%{
        transform:rotate(0deg);
        box-shadow:
            inset $fill 0 -0em 0 0,
            inset $bg 0 -4em 0 0,
            inset $fill 0 -8em 0 0;
    }
    80%{
        transform:rotate(0deg);
        box-shadow:
            inset $fill 0 -4em 0 0,
            inset $bg 0 -4em 0 0,
            inset $fill 0 -4em 0 0;
    }
    100%{
        transform:rotate(180deg);
        box-shadow:
            inset $fill 0 -4em 0 0,
            inset $bg 0 -4em 0 0,
            inset $fill 0 -4em 0 0;
    }
}


@media (max-width: 767px) {
    $width: 2em;
    $height:4em;

    .hourglass{
        display:block;
        background:$bg;
        margin:3em auto;
        width:$width;
        height:$height;
        animation:hourglass 1s linear infinite;
    }
    
    .outer{
        fill:$fill;
    }
    
    .middle{
        fill:$bg;
    }
    
    @keyframes hourglass{
        0%{
            transform:rotate(0deg);
            box-shadow:
                inset $fill 0 -0em 0 0,
                inset $bg 0 -2em 0 0,
                inset $fill 0 -4em 0 0;
        }
        80%{
            transform:rotate(0deg);
            box-shadow:
                inset $fill 0 -2em 0 0,
                inset $bg 0 -2em 0 0,
                inset $fill 0 -2em 0 0;
        }
        100%{
            transform:rotate(180deg);
            box-shadow:
                inset $fill 0 -2em 0 0,
                inset $bg 0 -2em 0 0,
                inset $fill 0 -2em 0 0;
        }
    }
}
@import '../../../../../styles/variables';

.popup-container {
  position: absolute;
  display: none;
  opacity: 0;
  // -webkit-transform: translate(50%, 50%) scale(0.2, 0.2);
  transform: translateX(50%) translateY(50%) scale(0.2);
  transition: opacity 0.3s, transform 0.3s;

  &.active {
    display: block;
    opacity: 1;
    // -webkit-transform: translate(0, 0) scale(1,1);
    transform: translateY(0) scale(1);
  }
  .popup {
    background-color: $white;
    color: $primary;
    padding: 15px;
    border-radius: 4px;
    z-index: 1000;
    width: 100%;
    height: 100%;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      font-family: 'Aeonik Bold';
      margin-bottom: 5px;

      .icon {
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    .content {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.1px;
    }
  }
}

/* Safari-specific styles */
// @supports (-webkit-appearance: none) {
//   .popup-container {
//     display: none;
//   }
//   .popup-container.active {
//     display: block;
//   }
// }

// .safari-only .popup-container {
//   display: none;
// }
// .safari-only .popup-container.active {
//   display: block;
// }

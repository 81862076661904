.info-box{
    cursor: pointer;
    // transition: transform .2s;
    margin: auto;
    // background-color: red;

    &:hover{
        // transform: scale(1.05);
        // background-color: aqua;
    }
}


  
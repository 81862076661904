.timelineCard {
  // box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    transform: scale(0.9, 0.9);
    // box-shadow: 5px 5px 30px 15px rgba(0,0,0,0.25),
    //   -5px -5px 30px 15px rgba(0,0,0,0.22);
    .timelineCardImg{
      // filter: brightness(120%) contrast(150%) hue-rotate(180deg) sepia(100%)  saturate(450%);
      // filter: invert(100%) sepia(29%) saturate(6540%) hue-rotate(181deg) brightness(111%) contrast(97%);
      filter: invert(0%) sepia(300%) saturate(6700%) hue-rotate(181deg) brightness(80%) contrast(125%);
    }
  }

  .timelineCardImg {
    // background: rgb(241 236 165 / 90%);
    opacity: 1;
    // filter: invert(97%) sepia(29%) saturate(6540%) hue-rotate(360deg) brightness(111%) contrast(97%);
    // filter: invert(100%) sepia(29%) saturate(6540%) hue-rotate(181deg) brightness(111%) contrast(97%);
    -moz-transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;

    &:hover {
      // filter: invert(12%) sepia(77%) saturate(3976%) hue-rotate(249deg) brightness(83%) contrast(140%);
      // filter: invert(15%) sepia(77%) saturate(2920%) hue-rotate(241deg) brightness(83%) contrast(140%);
      // filter: brightness(120%) contrast(150%) hue-rotate(200deg);
    }

    // &::after {
    //   position: absolute;
    //   content: '';
    //   height: 100%;
    //   width: 100%;
    //   background-color: rgb(21 27 158 / 50%);
    //   left: 0;
    //   right: 0;
    //   top: 0;
    //   bottom: 0;
    // }
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Aeonik Light';
  src: url('./assets/fonts/daylight-fonts/Aeonik-Light.woff2') format('woff2'),
      url('./assets/fonts/daylight-fonts/Aeonik-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Bold';
  src: url('./assets/fonts/daylight-fonts/Aeonik-Bold.woff2') format('woff2'),
      url('./assets/fonts/daylight-fonts/Aeonik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Regular';
  src: url('./assets/fonts/daylight-fonts/Aeonik-Regular.woff2') format('woff2'),
      url('./assets/fonts/daylight-fonts/Aeonik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@layer base {
  html {
    font-family: Aeonik Regular, sans-serif;
  }
}



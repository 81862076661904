@import '../../../../../../styles/variables';

.sidebar {
  width: 362px;
  height: 100%;
  background-color: $white;
  position: fixed;
  top: 0;
  padding: 24px 40px;
  right: -362px; /* Change the 'left' to 'right' */
  transition: right 0.3s ease-in-out; /* Change the transition property */

  &.open {
    right: 0; /* Change the 'left' to 'right' */
  }

  .close-button {
    padding: 10px;
    cursor: pointer;
    //   position: absolute;
    top: 20px;
    left: 20px; /* Adjust the positioning to the left side */
    transition: transform 0.3s ease-in-out;

    .bar {
      width: 30px;
      height: 3px;
      background-color: $primary;
      margin: 6px 0;
    }

    .bar1 {
      transform: rotate(45deg) translate(0, 5px); /* Add this */
    }

    .bar2 {
      transform: rotate(-45deg) translate(1px, -7px); /* Add this */
    }

    &:hover {
      transform: rotate(180deg);
    }
  }

  .menu {
    padding: 0;
    list-style: none;
    color: #000;

    li {
      color: $primary;
      border-bottom: 1px solid $quaternary;
      padding: 14px 0;
      cursor: pointer;
      &:last-of-type {
        border-bottom: 0;
      }

      &:hover {
        background: $neutral;
        
      }
      // span {
      //   display: inline-block; 
      //   transform: scale(1);
      //   &:hover {
      //     transform: scale(0.9);
      //   }
      // }
     
    }

    .active {
      background: $quaternary;
      
    }
  }

  @media (max-width: 425px) {
    width: 100%;
    right: -100%; /* Change the 'left' to 'right' */

    &.open {
      right: 0; /* Change the 'left' to 'right' */
    }
  }
}

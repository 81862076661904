@import '../../styles/variables';


.hamburgerButton {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 12px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  
    .bar {
      width: 30px;
      height: 3px;
      background-color: $primary;
      transition: transform 0.3s ease-in-out; /* Add this line */
    }
  
    .bar1.open {
      transform: rotate(45deg) translate(5px, 5px); /* Add this */
    }
  
    .bar3.open {
      transform: rotate(-45deg) translate(5px, -5px); /* Add this */
    }
    
  }
  
@import "../../styles/variables";

// $counter: 0;
// @function incrementCounter() {
//  $counter: $counter + 1 !global;
//   @return $counter;
// }

.timeline-graph-container {
  max-width: 1450px;
  align-items: center;
  // max-width: 1360px;
  margin: 0 auto;
  // margin-top: 8%;

  .timeline-graph-ul {
    display: flex;
    height: 440px;
    justify-content: center;
    position: relative;

    counter-reset: timeline-counter;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: $primary;
      top: 220px;
      right: auto;
      left: 0;
    }
    .timeline-graph-li {
      position: relative;
      counter-increment: timeline-counter;
      width: 100%;

      &::before {
        content: '';
        position: absolute;
        top: 125px;
        bottom: auto;
        border: 1px solid #15049e;
        left: 50%;
        // z-index: 9999;
        border-style: dashed;
        height: 20px;
      }

      &::after {
        content: counter(timeline-counter);
        position: absolute;
        width: 80px;
        height: 80px;
        top: 180px;
        background-color: $primary;
        border: 1px solid $quaternary;
        border-radius: 50%;
        z-index: 1;
        left: calc(50% - 42px);
        color: #fff;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:nth-child(even) {
        align-self: flex-end;

        counter-increment: timeline-counter;
        &::before {
          content: '';
          position: absolute;
          top: -25px;
          bottom: auto;
          border: 1px solid #15049e;
          left: 50%;
          // z-index: 9999;
          border-style: dashed;
          height: 20px;
        }
        &::after {
          content: counter(timeline-counter);
          position: absolute;
          width: 80px;
          height: 80px;
          top: -135px;
          background-color: $primary;
          border: 1px solid $quaternary;
          border-radius: 50%;
          z-index: 1;
          left: calc(50% - 42px);
          color: #fff;
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .top {
    }

    .bottom {
      // margin-top: 350px;
    }

    // .timeline-graph-li:nth-child(even){
    //     align-self: flex-end;

    //     counter-increment: timeline-counter;
    //     &::after {
    //         content: counter(timeline-counter);
    //         position: absolute;
    //         width: 80px;
    //         height: 80px;
    //         top: -135px;
    //         background-color: #15049E;
    //         border: 1px solid #15049E;
    //         border-radius: 50%;
    //         z-index: 1;
    //         left: calc(50% - 42px);
    //         color: #fff;
    //         font-size: 20px;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //     }
    // }
  }

  .timeline-graph-start {
    color: $primary;
    margin-right: 24px;
  }

  .timeline-graph-finish {
    display: flex;
    color: $primary;
    // z-index: 10;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid $primary;
    height: 80px;
    // margin-left: -25px;
    width: 80px;
    height: 80px;
    background-color: $quaternary;
  }
}

@media screen and (max-width: 1400px) {
  .timeline-graph-container {
    .timeline-graph-ul {
      height: 400px;
      .timeline-graph-li {
        width: 150px;

        &::before {
          top: 125px;
          height: 30px;
        }

        &::after {
          width: 60px;
          height: 60px;
          top: 190px;
          left: 31%;
        }

        &:nth-child(even) {
          &::before {
            top: -40px;
            left: 50%;
            height: 30px;
          }
          &::after {
            width: 60px;
            height: 60px;
            top: -115px;
            left: 31%;
          }
        }
      }
    }

    .timeline-graph-start {
      margin-top: 38px;
      color: $primary;
      margin-right: 24px;
    }

    .timeline-graph-finish {
      margin-top: 38px;
      width: 60px;
      height: 60px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .timeline-graph-container {
    margin-top: 0;
    .timeline-graph-ul {
      height: 380px;
      .timeline-graph-li {
        width: 130px;

        &::before {
          top: 125px;
          height: 20px;
        }

        &::after {
          width: 50px;
          height: 50px;
          top: 195px;
        }

        &:nth-child(even) {
          &::before {
            top: -25px;
            left: 50%;
            height: 20px;
          }
          &::after {
            width: 50px;
            height: 50px;
            top: -100px;
          }
        }
      }
    }

    .timeline-graph-start {
      margin-top: 60px;
    }

    .timeline-graph-finish {
      width: 50px;
      height: 50px;
      margin-top: 60px;
    }
  }
}

@media screen and (max-width: 991px) {
  .timeline-graph-container {
    flex-direction: column;
    margin-top: 0;
    .timeline-graph-ul {
      flex-direction: column;
      height: auto;
      width: 640px;
      right: auto;

      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 108%;
        background-color: $primary;
        top: 0;
        right: auto;
        left: 50%;
        bottom: 0;
      }

      .timeline-graph-li {
        width: 220px;

        &::before {
          top: 125px;
          height: 20px;
        }

        &::after {
          width: 50px;
          height: 50px;
          top: 195px;
        }

        &:nth-child(even) {
          align-self: flex-start;
          &::before {
            top: 50%;
            right: -35px;
            left: auto;
            height: 20px;
            transform: rotate(90deg);
          }
          &::after {
            width: 50px;
            height: 50px;
            top: auto;
            bottom: 25px;
            left: auto;
            right: -123px;
          }
        }

        &:nth-child(odd) {
          align-self: flex-end;
          &::before {
            top: 50%;
            left: -35px;
            height: 20px;
            transform: rotate(90deg);
          }
          &::after {
            width: 50px;
            height: 50px;
            top: auto;
            bottom: 25px;
            left: -123px;
            right: auto;
          }
        }
      }
    }

    .timeline-graph-start {
      margin-top: 0;
      margin-bottom: 24px;
      margin-left: 20px;
    }

    .timeline-graph-finish {
      width: 50px;
      height: 50px;
      margin-top: 60px;
    }
  }
}

@media screen and (max-width: 767px) {
  .timeline-graph-container {
    width: 380px;
    align-items: start;
    .timeline-graph-ul {
      width: 100%;
      &::after {
        left: 15px;
      }

      .timeline-graph-li {
        width: 220px;
        margin: 10px 0;

        &::before {
          top: 125px;
          height: 20px;
        }

        &::after {
          width: 50px;
          height: 50px;
          top: 195px;
        }

        &:nth-child(even) {
          align-self: center;
          &::before {
            top: 50%;
            right: auto;
            left: -35px;
            height: 20px;
            transform: rotate(90deg);
          }
          &::after {
            width: 50px;
            height: 50px;
            top: auto;
            bottom: 25px;
            left: -90px;
            right: auto;
          }
        }

        &:nth-child(odd) {
          align-self: center;
          &::before {
            top: 50%;
            left: -35px;
            height: 20px;
            transform: rotate(90deg);
          }
          &::after {
            width: 50px;
            height: 50px;
            top: auto;
            bottom: 25px;
            left: -90px;
            right: auto;
          }
        }
      }
    }

    .timeline-graph-start {
      margin-top: 0;
      margin-bottom: 24px;
      margin-left: 0;
    }

    .timeline-graph-finish {
      width: 50px;
      height: 50px;
      margin-top: 70px;
      margin-left: -10px;
    }
  }
}

@media screen and (max-width: 420px) {
    .timeline-graph-container {
      width: 260px;
      align-items: start;
      .timeline-graph-ul {
        width: 100%;
        &::after {
          left: 15px;
        }
  
        .timeline-graph-li {
          width: 160px;
          margin: 10px 0;
  
          &::before {
            top: 125px;
            height: 20px;
          }
  
          &::after {
            width: 10px;
            height: 10px;
            top: 195px;
          }
  
          &:nth-child(even) {
            &::before {
              left: -15px;
              top: 40%;
            }
            &::after {
              width: 30px;
              height: 30px;
              font-size: 12px;
              left: -50px;
              bottom: 35px;
            }
          }
  
          &:nth-child(odd) {
            &::before {
              left: -15px;
              top: 40%;
            }
            &::after {
              width: 30px;
              height: 30px;
              font-size: 12px;
              left: -50px;
              bottom: 35px;
            }
          }
        }
      }
  
      .timeline-graph-start {
        margin-top: 0;
        margin-bottom: 24px;
        margin-left: 0;
      }
  
      .timeline-graph-finish {
        width: 50px;
        height: 50px;
        margin-top: 60px;
        margin-left: -10px;
      }
    }
  }

